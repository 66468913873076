import React from "react"
import cx from "classnames"

import styles from "./Spinner.module.scss"

const Spinner = ({ className, inverted, size = "medium" }) => {
  return (
    <div
      className={cx(
        styles.spinner,
        [styles[size]],
        {
          [styles.inverted]: inverted,
        },
        className
      )}
    >
      <div />
      <div />
      <div />
    </div>
  )
}

export default Spinner
