import React from "react"

import Page from "components/Page"
import Container from "components/Container"

const TermsOfService = () => {
  return (
    <Page showFooterCTA={false}>
      <Container>Terms of Service (WIP)</Container>
    </Page>
  )
}

export default TermsOfService
