import React from "react"
import cx from "classnames"

import Button from "components/Button"
import Icon from "components/Icon"
import Container from "components/Container"
import Flex from "components/Flex"
import Link from "components/Link"
import { Grid, Cell } from "components/Grid"

import styles from "./Footer.module.scss"

const Footer = ({ showCTA = true }) => {
  return (
    <div className={styles.container}>
      {/* {showCTA && ( */}
      {false && (
        <Container className={styles.subscribe}>
          <div>
            <h2>Don't want to miss out?</h2>
            <p>
              We post like crazy, It's easy to miss a good post.
              <br />
              No spam, no jokes, just the cool stuff.
              <br />
              Pinky promise!
            </p>
          </div>

          <div>
            <input placeholder="Email address" />
            <Button.Secondary size="large">Join</Button.Secondary>
          </div>
        </Container>
      )}

      <Container
        containerClassName={cx(styles.footerContainer, {
          [styles.showCTA]: showCTA,
        })}
        className={styles.footer}
      >
        <div className={styles.limiter}>
          <Grid columns={5} gap={30} className={styles.footerGrid}>
            <Cell span={2}>
              <div className={styles.logo}>
                <Icon name="logo" width={40} height={40} />
                <span>Remot</span>
              </div>
            </Cell>

            <Cell span={3}>
              <Flex spaced grow>
                <div>
                  <h6>Pages</h6>

                  <ul className={styles.pages}>
                    <li>
                      <Link.UnderlineHover to="/">Home</Link.UnderlineHover>
                    </li>
                    <li>
                      <Link.UnderlineHover to="/blog">Blog</Link.UnderlineHover>
                    </li>
                    <li>
                      <Link.UnderlineHover to="/contact-us">
                        Contact us
                      </Link.UnderlineHover>
                    </li>
                  </ul>
                </div>

                <div>
                  <h6>Getting Started</h6>

                  <ul className={styles.pages}>
                    <li>
                      <Link.UnderlineHover to="/blog/how-to-start-remote-work">
                        How to start remote work
                      </Link.UnderlineHover>
                    </li>
                    <li>
                      <Link.UnderlineHover to="/blog/remote-quick-start-guide">
                        Quick Start Guide
                      </Link.UnderlineHover>
                    </li>
                    <li>
                      <Link.UnderlineHover to="/blog/transform-your-business">
                        Transform your business
                      </Link.UnderlineHover>
                    </li>
                  </ul>
                </div>

                <div>
                  <h6>Resources</h6>

                  <ul className={styles.pages}>
                    <li>
                      <Link.UnderlineHover to="#email-course">
                        Email Course
                      </Link.UnderlineHover>
                    </li>
                  </ul>
                </div>
              </Flex>
            </Cell>

            <Cell span={5} className={styles.footerLegal}>
              <span>2020 Remot Co. All rights reserved.</span>

              <ul>
                <li>
                  <Link.UnderlineHover to="/terms-of-service">
                    Terms of Service
                  </Link.UnderlineHover>
                </li>
                <li>
                  <Link.UnderlineHover to="/privacy-policy">
                    Privacy Policy
                  </Link.UnderlineHover>
                </li>
              </ul>
            </Cell>
          </Grid>
        </div>
      </Container>
    </div>
  )
}

export default Footer
