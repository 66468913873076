import * as React from "react"

import Spinner from "components/Spinner"

import styles from "./Button.module.scss"

const Button = ({
  className,
  children,
  disabled,
  loading,
  size = "medium",
  variant = "normal",
  ...rest
}) => (
  <button
    disabled={disabled || loading}
    className={[styles.button, styles[size], styles[variant], className]
      .filter(Boolean)
      .join(" ")}
    {...rest}
  >
    {loading ? <Spinner inverted size="small" /> : children}
  </button>
)

Button.CTA = ({ children, ...rest }) => (
  <Button variant="cta" {...rest}>
    {children}
  </Button>
)

Button.Secondary = ({ children, ...rest }) => (
  <Button variant="secondary" {...rest}>
    {children}
  </Button>
)

Button.Outline = ({ children, ...rest }) => (
  <Button variant="outline" {...rest}>
    {children}
  </Button>
)

Button.Oval = ({ children, ...rest }) => (
  <Button variant="oval" {...rest}>
    {children}
  </Button>
)

Button.Icon = ({ children, ...rest }) => (
  <Button variant="icon" {...rest}>
    {children}
  </Button>
)

Button.IconRect = ({ children, ...rest }) => (
  <Button variant="iconRect" {...rest}>
    {children}
  </Button>
)

Button.Danger = ({ children, ...rest }) => (
  <Button variant="danger" {...rest}>
    {children}
  </Button>
)

export default Button
