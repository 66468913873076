import React from "react"

import Footer from "components/Footer"
import Header from "components/Header"
import SEO from "components/SEO"

import styles from "./Page.module.scss"

const Page = ({ children, title, image, showFooterCTA }) => {
  return (
    <main className={styles.container}>
      <SEO title={title} image={image} />
      <Header />
      {children}
      <Footer showCTA={showFooterCTA} />
    </main>
  )
}

export default Page
