import React from "react"
import cx from "classnames"

import styles from "./Flex.module.scss"

export default function Flex({
  align = "start",
  className,
  children,
  spaced,
  vertical,
  center,
  grow,
  cover,
  ...rest
}) {
  return (
    <div
      className={cx(
        styles.container,
        styles[align],
        {
          [styles.spaced]: spaced,
          [styles.vertical]: vertical,
          [styles.grow]: grow,
          [styles.centerXY]: center,
          [styles.cover]: cover,
        },
        className
      )}
      {...rest}
    >
      {children}
    </div>
  )
}
