import React from "react"

const getColumns = value =>
  typeof value === "number" ? `repeat(${value}, 1fr)` : value

const formatAreas = areas => {
  if (!areas) return

  return areas
    .split("\n")
    .map(item => {
      const value = item.trim()
      return value ? `"${value}"` : null
    })
    .filter(Boolean)
    .join(" ")
}

export default function Grid({
  className,
  children,
  areas,
  rows,
  alignItems,
  justifyItems,
  alignContent,
  justifyContent,
  gap = 0,
  columns = 12,
  style = {},
}) {
  return (
    <div
      className={className}
      style={{
        alignItems,
        justifyItems,
        alignContent,
        justifyContent,
        display: "grid",
        gridTemplateColumns: getColumns(columns),
        gridTemplateRows: rows,
        gridTemplateAreas: formatAreas(areas),
        gridGap: gap,
        ...style,
      }}
    >
      {children}
    </div>
  )
}
